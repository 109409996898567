exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-choose-school-tsx": () => import("./../../../src/pages/choose-school.tsx" /* webpackChunkName: "component---src-pages-choose-school-tsx" */),
  "component---src-pages-dashboard-admin-class-edit-tsx": () => import("./../../../src/pages/dashboard/admin/class/edit.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-class-edit-tsx" */),
  "component---src-pages-dashboard-admin-class-index-tsx": () => import("./../../../src/pages/dashboard/admin/class/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-class-index-tsx" */),
  "component---src-pages-dashboard-admin-class-new-tsx": () => import("./../../../src/pages/dashboard/admin/class/new.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-class-new-tsx" */),
  "component---src-pages-dashboard-admin-curriculum-area-tsx": () => import("./../../../src/pages/dashboard/admin/curriculum/area.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-curriculum-area-tsx" */),
  "component---src-pages-dashboard-admin-curriculum-index-tsx": () => import("./../../../src/pages/dashboard/admin/curriculum/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-curriculum-index-tsx" */),
  "component---src-pages-dashboard-admin-curriculum-material-index-tsx": () => import("./../../../src/pages/dashboard/admin/curriculum/material/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-curriculum-material-index-tsx" */),
  "component---src-pages-dashboard-admin-curriculum-subjects-index-tsx": () => import("./../../../src/pages/dashboard/admin/curriculum/subjects/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-curriculum-subjects-index-tsx" */),
  "component---src-pages-dashboard-admin-guardians-index-tsx": () => import("./../../../src/pages/dashboard/admin/guardians/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-guardians-index-tsx" */),
  "component---src-pages-dashboard-admin-guardians-new-tsx": () => import("./../../../src/pages/dashboard/admin/guardians/new.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-guardians-new-tsx" */),
  "component---src-pages-dashboard-admin-guardians-profile-tsx": () => import("./../../../src/pages/dashboard/admin/guardians/profile.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-guardians-profile-tsx" */),
  "component---src-pages-dashboard-admin-index-tsx": () => import("./../../../src/pages/dashboard/admin/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-index-tsx" */),
  "component---src-pages-dashboard-admin-invite-user-index-tsx": () => import("./../../../src/pages/dashboard/admin/inviteUser/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-invite-user-index-tsx" */),
  "component---src-pages-dashboard-admin-school-profile-tsx": () => import("./../../../src/pages/dashboard/admin/school-profile.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-school-profile-tsx" */),
  "component---src-pages-dashboard-admin-students-index-tsx": () => import("./../../../src/pages/dashboard/admin/students/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-students-index-tsx" */),
  "component---src-pages-dashboard-admin-subscription-index-tsx": () => import("./../../../src/pages/dashboard/admin/subscription/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-subscription-index-tsx" */),
  "component---src-pages-dashboard-admin-subscription-success-tsx": () => import("./../../../src/pages/dashboard/admin/subscription/success.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-subscription-success-tsx" */),
  "component---src-pages-dashboard-admin-user-profile-tsx": () => import("./../../../src/pages/dashboard/admin/user-profile.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-user-profile-tsx" */),
  "component---src-pages-dashboard-admin-users-tsx": () => import("./../../../src/pages/dashboard/admin/users.tsx" /* webpackChunkName: "component---src-pages-dashboard-admin-users-tsx" */),
  "component---src-pages-dashboard-reports-index-tsx": () => import("./../../../src/pages/dashboard/reports/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-reports-index-tsx" */),
  "component---src-pages-dashboard-reports-manage-index-tsx": () => import("./../../../src/pages/dashboard/reports/manage/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-reports-manage-index-tsx" */),
  "component---src-pages-dashboard-reports-manage-student-index-tsx": () => import("./../../../src/pages/dashboard/reports/manage/student/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-reports-manage-student-index-tsx" */),
  "component---src-pages-dashboard-reports-new-tsx": () => import("./../../../src/pages/dashboard/reports/new.tsx" /* webpackChunkName: "component---src-pages-dashboard-reports-new-tsx" */),
  "component---src-pages-dashboard-students-gallery-image-tsx": () => import("./../../../src/pages/dashboard/students/gallery/image.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-gallery-image-tsx" */),
  "component---src-pages-dashboard-students-gallery-index-tsx": () => import("./../../../src/pages/dashboard/students/gallery/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-gallery-index-tsx" */),
  "component---src-pages-dashboard-students-index-tsx": () => import("./../../../src/pages/dashboard/students/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-index-tsx" */),
  "component---src-pages-dashboard-students-new-add-guardian-tsx": () => import("./../../../src/pages/dashboard/students/new/add-guardian.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-new-add-guardian-tsx" */),
  "component---src-pages-dashboard-students-new-index-tsx": () => import("./../../../src/pages/dashboard/students/new/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-new-index-tsx" */),
  "component---src-pages-dashboard-students-observation-details-tsx": () => import("./../../../src/pages/dashboard/students/observation-details.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-observation-details-tsx" */),
  "component---src-pages-dashboard-students-observations-all-tsx": () => import("./../../../src/pages/dashboard/students/observations/all.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-observations-all-tsx" */),
  "component---src-pages-dashboard-students-observations-details-tsx": () => import("./../../../src/pages/dashboard/students/observations/details.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-observations-details-tsx" */),
  "component---src-pages-dashboard-students-observations-new-tsx": () => import("./../../../src/pages/dashboard/students/observations/new.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-observations-new-tsx" */),
  "component---src-pages-dashboard-students-overview-tsx": () => import("./../../../src/pages/dashboard/students/overview.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-overview-tsx" */),
  "component---src-pages-dashboard-students-plans-details-tsx": () => import("./../../../src/pages/dashboard/students/plans/details.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-plans-details-tsx" */),
  "component---src-pages-dashboard-students-plans-index-tsx": () => import("./../../../src/pages/dashboard/students/plans/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-plans-index-tsx" */),
  "component---src-pages-dashboard-students-plans-new-tsx": () => import("./../../../src/pages/dashboard/students/plans/new.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-plans-new-tsx" */),
  "component---src-pages-dashboard-students-profile-classes-edit-tsx": () => import("./../../../src/pages/dashboard/students/profile/classes/edit.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-profile-classes-edit-tsx" */),
  "component---src-pages-dashboard-students-profile-classes-new-tsx": () => import("./../../../src/pages/dashboard/students/profile/classes/new.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-profile-classes-new-tsx" */),
  "component---src-pages-dashboard-students-profile-guardians-add-tsx": () => import("./../../../src/pages/dashboard/students/profile/guardians/add.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-profile-guardians-add-tsx" */),
  "component---src-pages-dashboard-students-profile-guardians-profile-tsx": () => import("./../../../src/pages/dashboard/students/profile/guardians/profile.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-profile-guardians-profile-tsx" */),
  "component---src-pages-dashboard-students-profile-index-tsx": () => import("./../../../src/pages/dashboard/students/profile/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-profile-index-tsx" */),
  "component---src-pages-dashboard-students-progress-tsx": () => import("./../../../src/pages/dashboard/students/progress.tsx" /* webpackChunkName: "component---src-pages-dashboard-students-progress-tsx" */),
  "component---src-pages-dashboard-support-index-tsx": () => import("./../../../src/pages/dashboard/support/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-support-index-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-new-school-tsx": () => import("./../../../src/pages/new-school.tsx" /* webpackChunkName: "component---src-pages-new-school-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */)
}

