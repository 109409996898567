module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Obserfy","short_name":"Obserfy","start_url":"/dashboard/students","background_color":"#121212","theme_color":"#121212","display":"standalone","icon":"src/images/logo-transparent.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fe67918dbda465cf53640b9ec939e54c"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#00a06d","showSpinner":false},
    },{
      plugin: require('../../../../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/usr/src/apps/vor/frontend/i18n/config.json"},
    },{
      plugin: require('../../../../node_modules/gatsby-theme-i18n-lingui/gatsby-browser.js'),
      options: {"plugins":[],"localeDir":"./i18n/lingui"},
    },{
      plugin: require('../../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://05a5ecaa1d8c4c01b96d2a7993fa9337@sentry.io/1852524","release":"1.17.6","tracesSampleRate":0.1},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
